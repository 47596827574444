import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Button, Checkbox, Input, InputNumber, Table, Typography} from "antd";
import {getDefaultColumns, useColumns} from "../Stages/Shared";

const {Text} = Typography;

export const OligsToProduction = ({selectedItems, handleSubmit, handleCancel}) => {
    const [tableData, setTableData] = useState(selectedItems);
    const {columns, filters} = useColumns();

    const handleQuantityChange = (value, record) => {
        const newData = [...tableData];
        const index = newData.findIndex((item) => item.id === record.id);
        newData[index].to_production = value;
        setTableData(newData);
    };

    const handleIsPlannedChange = (checked, record) => {
        const newData = [...tableData];
        const index = newData.findIndex((item) => item.id === record.id);
        newData[index].is_planned = checked;
        setTableData(newData);
    };

    const getColumns = () => {

        let addColumns = [
            {
                title: 'Заказано',
                dataIndex: 'need',
                key: 'need',
                width: 150,
                render: (text, record) => <div>{parseFloat(record.ngs_sum || 0)}</div>,
            },
            {
                title: 'Расфасовано',
                dataIndex: 'portion_is_sent_sum',
                key: 'portion_is_sent_sum',
                width: 150,
                render: (value) => <div>{parseFloat(value || 0)}</div>,
            },
            {
                title: 'В производстве', dataIndex: 'process', key: 'process', width: 150,
                render: (value, record) => {
                    const text = value ? 'в наличии' : 'отсутствуют';
                    return value ?
                        <Link target={'_blank'} to={`/portion/?select=${record.full_sequence}`}>
                            <Text type={"success"}>{text}</Text>
                        </Link> :
                        <Text type={"danger"}>{text}</Text>;
                }
            },
            {
                title: 'База остатков',
                dataIndex: 'portion_in_stock_sum',
                key: 'portion_in_stock_sum',
                width: 150,
                sorter: true,
                render: (value, item) => {
                    return value || '-';
                }
            },
            {
                title: 'Количество',
                dataIndex: 'to_production',
                key: 'to_production',
                width: 150,
                render: (value, record) => {
                    return <InputNumber type={'number'} value={value} onChange={(v) => {
                        handleQuantityChange(parseFloat(v), record)
                    }}/>

                },
            },
            {
                title: () => {
                    return <label><Checkbox style={{marginRight: 10}} onChange={(e) => {
                        const checked = e.target.checked;
                        const newData = [...tableData];
                        newData.forEach((item) => {
                            item.is_planned = checked;
                        });
                        setTableData(newData);
                    }}/>Будет спланировано</label>
                },
                dataIndex: 'is_planned',
                key: 'is_planned',
                width: 200,
                render: (text, record) => (
                    <Checkbox
                        checked={text}
                        onChange={(e) => handleIsPlannedChange(e.target.checked, record)}
                    />
                ),
            },
        ];

        addColumns = columns.concat(addColumns);
        const onlyColumns = ['olig_name', 'olig_sequence', 'modification_5', 'modification_3', 'label', 'length',
            'done', 'process', 'need', 'to_production', 'is_planned', 'portion_in_stock_sum', 'portions_work_sum',
            'nanomole', 'portion_is_sent_sum',
        ];
        addColumns = addColumns.filter((item) => onlyColumns.includes(item.dataIndex));
        return addColumns;

    }

    const handleSubmitForm = () => {
        let values = tableData.map((item) => ({
            id: item.id,
            quantity: item.to_production,
            ngs: item.ngs,
            is_planned: item.is_planned,
        }));
        values = { oligonucleotides: values.filter((item) => item.quantity > 0) };
        handleSubmit(values);
    };

    function getFilteredData() {
        let data = tableData;
        for (let field in filters) {
            if (filters[field] !== '') {
                data = data.filter((item) => {
                    return item[field].toString().toLowerCase().includes(filters[field].toString().toLowerCase());
                });
            }
        }
        data.forEach((item) => {
            item.olig_sequence = item.full_sequence;
            item.work_sequence = item.full_sequence;
            item.target_sequence = item.full_sequence;
        });
        return data;
    }

    return (
        <>
            <Table dataSource={getFilteredData()}
                   size={'small'}
                   columns={getColumns()}
                   pagination={false}
                   rowKey="id"
            />
            <div style={{textAlign: 'right', marginTop: 16}}>
                <Button onClick={handleCancel}>Отменить</Button>
                <Button type="primary" onClick={handleSubmitForm} style={{marginLeft: 8}}>
                    Отправить
                </Button>
            </div>
        </>
    );
};